import { isSameDay } from '@rover/react-lib/src/utils/datetime';

const createRenderDayForHolidays =
  (holidays: Date[]) =>
  (day: Date): string => {
    const dayIsHoliday = holidays.find((holiday) => isSameDay(holiday, day));
    const formattedDate = day.getDate().toString();
    return dayIsHoliday ? `${formattedDate}*` : formattedDate;
  };

export default createRenderDayForHolidays;

import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import styled, { css } from 'styled-components';

import { Box } from '@rover/kibble/core';
import { DSTokenMap, MQ } from '@rover/kibble/styles';
import FormBasicValidationError from '@rover/react-lib/src/components/formFields/FormValidationError/FormBasicValidationError';

type InlineErrorDateInputProps = {
  onKeyDown?: (any) => void;
  value: string;
  inputId?: string;
  errorMessage: errorMessageType;
  placeholder?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

// errorMessageType needs to accept all translation formats, i.e. t`, <Trans> and i18n strings
export type errorMessageType = MessageDescriptor | JSX.Element | string | null | undefined;

export const DateInput = styled.input<{ errorMessage: errorMessageType }>`
  ${MQ.XS.toString()} {
    &:focus {
      font-size: 16px;
    }
  }
  ${({ errorMessage }) =>
    errorMessage
      ? css`
          border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
            ${DSTokenMap.BORDER_COLOR_ERROR.toString()} !important;
        `
      : css`
          border: ${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid
            ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()} !important;
        `}
`;

const InlineErrorDateInput = ({
  errorMessage,
  onKeyDown,
  value,
  inputId,
  placeholder = undefined,
  onClick,
  style,
  ...other
}: InlineErrorDateInputProps): JSX.Element => {
  return (
    <Box role="button" onClick={onClick}>
      <DateInput
        type="text"
        style={{ cursor: 'pointer', ...style }}
        readOnly
        onKeyDown={onKeyDown}
        value={value}
        id={inputId}
        errorMessage={errorMessage}
        placeholder={placeholder}
        aria-labelledby={other['aria-labelledby']}
      />
      {errorMessage && <FormBasicValidationError errorMessage={errorMessage} />}
    </Box>
  );
};

export default InlineErrorDateInput;

/**
 * On the backend, MAX_CALENDAR_DAYS_IN_FUTURE is an arbitrary number we chose to limit people from
 * creating service requests too far in the future. It is set to 450 on the backend. We are making it 400 here because
 * of an edge case in on how we create next week's days for repeat indicated needs (only available in EU, won't be an issue
 * if/when we launch recurring in EU). Check getNextDateForDayOfWeek in ConcreteDayOfWeekPicker for more.
 */
const MAX_CALENDAR_DAYS_IN_FUTURE = 400;

function getMaxRequestDate(): Date {
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + MAX_CALENDAR_DAYS_IN_FUTURE);
  return maxDate;
}

export default getMaxRequestDate;

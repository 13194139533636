import React from 'react';
import type { LocaleUtils } from 'react-day-picker/types/LocaleUtils';

import { Text } from '@rover/kibble/core';

export type Props = {
  classNames: {
    caption: string;
  };
  date: Date;
  locale: string;
  localeUtils: LocaleUtils;
  months: string[];
};

const DatePickerCaption = ({
  classNames,
  date,
  locale,
  localeUtils,
  months,
}: Props): JSX.Element => {
  const heading = months
    ? `${months[date.getMonth()]} ${date.getFullYear()}`
    : localeUtils.formatMonthTitle(date, locale);
  return (
    <div className={classNames.caption}>
      <Text as="div" size="200" fontWeight="semibold" marginTop="3x" marginBottom="2x" marginX="2x">
        {heading}
      </Text>
    </div>
  );
};

export default DatePickerCaption;

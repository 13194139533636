/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable rover/no-platform-specific-globals-or-imports, max-classes-per-file */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DayPicker from 'react-day-picker/DayPicker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { t } from '@lingui/macro';
import moment from 'moment';
import styled from 'styled-components';

import { Calendar, Close } from '@rover/icons';
import { Box, Button, Heading, Paragraph } from '@rover/kibble/core';
import { Color, DSTokenMap, Spacing } from '@rover/kibble/styles';
import CalendarBottomSheet from '@rover/react-lib/src/components/BottomSheet/CalendarBottomSheet';
import InlineErrorDateInput, {
  errorMessageType,
} from '@rover/react-lib/src/components/formFields/InlineErrorDateInput/InlineErrorDateInput';
import type { Props as LabelAndErrorFormGroupProps } from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import LabelAndErrorFormGroup from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import useOnClickOutside from '@rover/react-lib/src/hooks/useOnClickOutside';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { I18nType, useI18n } from '@rover/rsdk/src/modules/I18n';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import type { Day } from '@rover/types';
import { DateRangeField } from '@rover/types/src/datetime/DateRange';
import Enum from '@rover/types/src/Enum';
import { toMessageDescriptor } from '@rover/utilities/i18n';

import '@rover/utilities/allMomentLocales';
import 'react-day-picker/lib/style.css';

import MultiDatePicker from '../MultiDatePicker';

import createRenderDayForHolidays from './createRenderDayForHolidays';
import DatePickerCaption from './DatePickerCaption';
import DatePickerNavbar from './DatePickerNavbar';
import DatePickerStylingWrapper from './DatePickerStylingWrapper';

const SELECT_START_DATE = t`Select a start date`;
export const StyledIcon = styled(Calendar)`
  position: absolute;
  height: ${(props) => props.iconStyle?.height ?? '16px'};
  width: ${(props) => props.iconStyle?.width ?? '16px'};
  right: ${(props) => props.iconStyle?.right ?? Spacing.M.toString()};
  top: 11px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

const StyledCloseIcon = styled(Close)`
  height: 16px;
  width: 16px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

export class CalendarDirection extends Enum<string> {
  static UP = new CalendarDirection('up');

  static DOWN = new CalendarDirection('down');

  static FLEX = new CalendarDirection('flex');

  static isLocked = true;
}

export type Props = Omit<LabelAndErrorFormGroupProps, 'placeholder'> & {
  allowKeyboardInput?: boolean;
  asCalendar?: boolean;
  calendarDirection?: CalendarDirection;
  className?: string;
  date?: DateRangeField;
  holidays?: Date[];
  id?: string;
  initialMonth?: Date;
  isDayBlocked?: (arg0: Date) => boolean;
  language: string;
  maxDate?: DateRangeField;
  minDate?: DateRangeField;
  // `modifiers` is defined by react-day-picker and can literally be anything
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modifiers?: Record<string, any>;
  onChange: (arg0: Date | undefined) => void;
  placeholder?: string | MessageDescriptor;
  newCalendarInteraction?: boolean;
  serviceSubtitle?: string | MessageDescriptor;
  serviceName?: string | MessageDescriptor;
  disableValidation?: boolean;
  isMobileBrowser?: boolean;
  validationType: 'popover' | 'inline';
  inputStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  onClickFromSearch?: () => void;
  ariaLabelledBy?: string;
  onInputBlur?: (e?: React.FocusEvent<HTMLDivElement, Element>, isDateValid?: boolean) => void;
};

export function parseDatePopoverErrors(
  validationErrors,
  isInExperiment
): undefined | string | MessageDescriptor {
  /* The component receives errors from MultiDateTimePicker, which contain both date and time errors.
  To show the correct message on the calendar, we need to distinguish between them: one error = date validation,
  list of errors = time picker's validation. In the future, popovers will be deprecated and date/time components
  separated - remember to get rid of this function if newCalendarInteraction experiment wins! */
  if (isInExperiment) {
    return undefined;
  }
  return Array.isArray(validationErrors) ? undefined : validationErrors;
}

type DayPickerInputProps = React.ComponentProps<typeof DayPickerInput>;

const DATE_PICKER_CALENDAR_HEIGHT = 320;

export type DatePickerHandle = {
  showDatePicker: () => void;
};

type KeyboardEvent = {
  key: string;
  preventDefault(): void;
};

function checkDateErrors(selectedDate, disableValidation = false): MessageDescriptor | undefined {
  if (disableValidation) return undefined;

  if (!selectedDate) {
    return SELECT_START_DATE;
  }
  return undefined;
}

const DatePicker = forwardRef<DatePickerHandle, Props>(function DatePicker(
  {
    allowKeyboardInput,
    asCalendar,
    className,
    date,
    id,
    isDayBlocked,
    language,
    minDate,
    maxDate,
    initialMonth,
    onChange,
    placeholder,
    calendarDirection,
    holidays,
    modifiers,
    validationError: validationErrorProp,
    validationType,
    newCalendarInteraction = false,
    serviceName,
    serviceSubtitle,
    disableValidation = false,
    isMobileBrowser,
    inputStyle,
    iconStyle,
    onClickFromSearch,
    ariaLabelledBy,
    onInputBlur,
    ...other
  }: Props,
  externalDatepickerRef
) {
  let focusTimeout: ReturnType<typeof setTimeout> | undefined;
  let blurTimeout: ReturnType<typeof setTimeout> | undefined;
  let observer: IntersectionObserver | undefined;
  let potentialValidationError: errorMessageType;
  let isObserving = false;

  const [userIsTouching, setUserIsTouching] = useState(false);
  const [validationError, setValidationError] = useState<errorMessageType>(undefined);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [calendarDropUp, setCalendarDropUp] = useState(false);
  const [calendarHeightOffset, setCalendarHeightOffset] = useState(40);
  const [calendarInset, setCalendarInset] = useState(0);
  const [touchingWhileOpen, setTouchingWhileOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(date || null);

  const { i18n } = useI18n();

  useEffect(() => {
    if (date) setSelectedDate(date);
  }, [date]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dayPickerRef = useRef<DayPickerInput>(null);
  useOnClickOutside(wrapperRef, () => {
    setShowCalendar(false);
    if (showCalendar) {
      if (!disableValidation) setValidationError(checkDateErrors(selectedDate));
    }
  });
  const inputPassedRootMargin = (entries: IntersectionObserverEntry[]): void => {
    if (!isObserving) return; // This prevents the function from executing if it's still in the JS event loop queue when unobserve is called

    entries.forEach((entry) => {
      setCalendarDropUp(
        calendarDirection !== CalendarDirection.DOWN && entry.intersectionRatio < 1
      );
    });
  };

  const getObserver = (): IntersectionObserver =>
    new IntersectionObserver(inputPassedRootMargin, {
      root: null,
      rootMargin: `0px 0px -${DATE_PICKER_CALENDAR_HEIGHT}px 0px`,
      threshold: 1.0,
    });

  const observe = (): void => {
    isObserving = true;
    observer = getObserver();
    if (!(wrapperRef.current instanceof HTMLElement)) return;
    observer.observe(wrapperRef.current);
    const inputHeight = wrapperRef.current.getBoundingClientRect().height;
    setCalendarHeightOffset(inputHeight);
  };

  const unobserve = (): void => {
    if (observer && wrapperRef?.current) {
      observer.unobserve(wrapperRef.current);
      isObserving = false;
    }
  };

  useEffect(() => {
    return (): void => {
      unobserve();
      clearTimeout(focusTimeout);
      clearTimeout(blurTimeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dayPickerLateralAdjust = (): void => {
    const windowWidth = window.innerWidth;
    const wrapperEl = wrapperRef?.current?.getElementsByClassName('DayPickerInput-Overlay')[0];
    if (!wrapperEl) return;
    const { width: calendarWidth } = wrapperEl.getBoundingClientRect();
    const { left: inputLeft, right: inputRight } =
      wrapperRef?.current?.getBoundingClientRect() || {};
    const calendarRight = inputLeft + calendarWidth;
    let inset = 0;

    // If the calendar goes off the right side of the screen, try the left instead
    if (calendarRight >= windowWidth) {
      const overshootRight = calendarRight - windowWidth;
      inset = calendarRight - inputRight;
      const overshootLeft = inset - inputLeft;

      // If the calendar overshoots the left more than the right, reset to right
      if (overshootLeft > overshootRight) {
        inset = 0;
      }
    }

    // Make sure the calendar is inside the right side of the screen
    if (calendarRight - inset > windowWidth) {
      inset = calendarRight - windowWidth;
    }

    // Make sure the calendar is inside the left side of the screen
    if (inputLeft - inset < 0) {
      inset = inputLeft;
    }

    setCalendarInset(inset);
  };

  const handleDayChange: DayPickerInputProps['onDayChange'] = (newDate: Date, { disabled }) => {
    if (disabled) return;

    if (newDate) {
      potentialValidationError = undefined;
      setValidationError(undefined);
      onChange(newDate);
      setSelectedDate(newDate);
    } else {
      potentialValidationError = `Use the format ${
        getDateTimeFormatMapForLang(language).DATE_SHORT
      }`;
      setSelectedDate(null);
    }
  };

  const handleOutsideTouchMove = (): void => setTouchingWhileOpen(true);
  const handleOutsideTouchEnd = useCallback(
    (event: Event): void => {
      // Close if there was no scrolling
      if (!touchingWhileOpen) {
        // `dayPickerRef.getDayPicker().dayPicker` has the DOM element
        const dayPickerComponent = dayPickerRef?.current?.getDayPicker();

        if (dayPickerComponent) {
          const dayPickerEl = dayPickerComponent.dayPicker;
          const dayPickerInputEl = dayPickerRef?.current?.getInput();

          const targetIsOutsideDayPicker =
            !dayPickerEl.contains(event.target as Node) &&
            !dayPickerInputEl.contains(event.target as Node);

          if (targetIsOutsideDayPicker && dayPickerRef?.current) {
            dayPickerRef.current.hideDayPicker();
          }
        }
      }

      setTouchingWhileOpen(false);
    },
    [touchingWhileOpen]
  );

  useEffect(() => {
    if (calendarIsOpen) {
      document.addEventListener('touchend', handleOutsideTouchEnd, false);
    }
    return (): void => {
      document.removeEventListener('touchend', handleOutsideTouchEnd, false);
    };
  }, [calendarIsOpen, handleOutsideTouchEnd]);

  useEffect(() => {
    if (showCalendar && newCalendarInteraction && isMobileBrowser) {
      document.querySelector('.DayPicker-Day.DayPicker-Day--today')?.scrollIntoView(false);
    }
    if (showCalendar && onClickFromSearch) onClickFromSearch();
  }, [showCalendar]);

  const handleDayPickerShow = (): void => {
    dayPickerLateralAdjust();
    // Add a slight delay to allow the IntersectionObserver to update before we display the dropdown
    focusTimeout = setTimeout(() => setCalendarIsOpen(true), 100);
    observe();
    document.addEventListener('touchmove', handleOutsideTouchMove, false);
  };

  const handleDayPickerHide = (): void => {
    unobserve();
    setCalendarIsOpen(false);
    document.removeEventListener('touchmove', handleOutsideTouchMove, false);
  };

  const handleInputBlur = (event?: React.FocusEvent<HTMLDivElement, Element>): void => {
    onInputBlur?.(event, !!selectedDate);
    // setTimeout here ensures this.state.calendarIsOpen below accurately reflects the UI
    setTimeout(() => {
      // avoid inadvertently closing the calendar UI by only updating state when the calendar is closed
      if (potentialValidationError && !calendarIsOpen && !disableValidation) {
        setValidationError(potentialValidationError);
      }
    }, 0);
  };

  // last day in the array is the one the user selected
  const handleCalendarChange = (selectedDays: Day[]): void => {
    const addedDay = selectedDays.pop();
    if (addedDay) onChange(addedDay.date ? addedDay.date : undefined);
  };

  const handleTouchStart = (): void => {
    // `userIsTouching` temporarily sets input to readonly to avoid popping keyboard on mobile
    setUserIsTouching(true);
  };

  const showDatePicker = (): void => {
    if (dayPickerRef?.current) {
      dayPickerRef.current.getInput().focus();
      observe();
      dayPickerRef.current.showDayPicker();
      setTimeout(() => dayPickerLateralAdjust());
    }
  };

  useImperativeHandle(externalDatepickerRef, () => ({ showDatePicker }));

  const dayPickerProps = {
    value: date,
    fromMonth: minDate,
    toMonth: maxDate,
    month: initialMonth || minDate,
    disabledDays: [
      {
        before: minDate,
        after: maxDate,
      },
      isDayBlocked,
    ],
    selectedDays: selectedDate ? [selectedDate] : [],
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    localeUtils: MomentLocaleUtils,
    locale: language,
    modifiers: {
      holiday: holidays,
      ...modifiers,
    },
    renderDay: createRenderDayForHolidays(holidays || []),
  };

  const readOnly = allowKeyboardInput ? userIsTouching : true;

  function getStringFromDates(): string {
    if (selectedDate) {
      const firstDay = moment(selectedDate);
      return firstDay.format('ddd, DD MMM YYYY');
    }
    return '';
  }

  function clearDateSelection<T extends Date>(
    removedDate: T,
    handleChange: (date: Date) => void
  ): void {
    if (removedDate) {
      setSelectedDate(null);
    }
    handleChange(removedDate);
  }

  const numberOfMonthsAllowed = (): number => {
    if (maxDate) {
      // Adding 2 to the result to count both first and last month
      return Math.abs(moment().diff(moment(maxDate), 'months')) + 2;
    }
    // Adding 2 to the result to count both first and last month
    return Math.abs(moment().diff(moment(getMaxRequestDate()), 'months')) + 2;
  };

  const renderInput = (isMobile = false): React.ReactNode => {
    return (
      <Box
        onClick={(): void => {
          !isMobile && setShowCalendar(!showCalendar);
        }}
        className="multiDateAutoFillPicker-clickable-wrapper"
        maxHeight={40}
        width={isMobileBrowser && showCalendar ? '80vw' : 'unset'}
        position="relative"
        marginBottom="4x"
      >
        <InlineErrorDateInput
          inputId={id}
          style={inputStyle}
          onKeyDown={(event: KeyboardEvent): void => {
            if (event.key === 'Enter') {
              setShowCalendar(!showCalendar);
            } else if (event.key === ' ') {
              event.preventDefault();
              setShowCalendar(!showCalendar);
            }
          }}
          value={getStringFromDates()}
          errorMessage={validationError || validationErrorProp}
          placeholder={
            typeof placeholder === 'string' ? placeholder : i18n._(toMessageDescriptor(placeholder))
          }
          aria-labelledby={ariaLabelledBy}
        />
        {isMobile && selectedDate && showCalendar ? (
          <Box
            position="absolute"
            width="16px"
            height="16px"
            right={Spacing.M.toString()}
            bottom="11px"
            onClick={(): void => clearDateSelection(selectedDate, onChange)}
          >
            <StyledCloseIcon />
          </Box>
        ) : (
          <StyledIcon iconStyle={iconStyle} />
        )}
      </Box>
    );
  };

  const renderMobile = (formGroupProps): JSX.Element => {
    return (
      <Box
        position="fixed"
        pb="0x"
        pl="0x"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        sx={{
          backgroundColor: DSTokenMap.BACKGROUND_COLOR_OVERLAY.toString(),
        }}
        zIndex="10000"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <CalendarBottomSheet reference={wrapperRef} onClose={() => setShowCalendar(false)}>
          <Heading size="200" mt="8x" mb="1x">
            {i18n._(serviceName as string)}
          </Heading>
          <Paragraph size="100" mb="6x" textAlign="center" maxWidth="80vw">
            {i18n._(serviceSubtitle as string)}
          </Paragraph>
          {renderInput(true)}
          <Box
            sx={{
              overflow: 'scroll',
            }}
          >
            <DayPicker
              {...formGroupProps}
              {...dayPickerProps}
              onDayClick={handleDayChange}
              ref={dayPickerRef}
              canChangeMonth={false}
              numberOfMonths={numberOfMonthsAllowed()}
            />
          </Box>
          <Box
            position="fixed"
            bottom="0"
            padding="2x"
            paddingBottom="6x"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{
              backgroundColor: Color.NEUTRAL_WHITE.toString(),
            }}
            width="100vw"
            height="64px"
            zIndex="30"
          >
            <Button
              backgroundColor={DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY.toString()}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              color={DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()}
              onClick={(): void => {
                const error = checkDateErrors(selectedDate);
                if (!disableValidation) setValidationError(error);
                if (!error) {
                  setShowCalendar(false);
                }
              }}
            >
              {i18n._(t`Save dates`)}
            </Button>
          </Box>
        </CalendarBottomSheet>
      </Box>
    );
  };

  const renderDesktop = (formGroupProps): JSX.Element => {
    return (
      <Box
        pb="0x"
        mt="5x"
        sx={{ backgroundColor: 'white' }}
        width="640px"
        border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
      >
        <DayPicker
          {...formGroupProps}
          {...dayPickerProps}
          onDayClick={handleDayChange}
          ref={dayPickerRef}
          numberOfMonths={2}
        />
      </Box>
    );
  };

  const dayPickerInputProps = {
    value: date,
    onDayChange: handleDayChange,
    onDayPickerShow: handleDayPickerShow,
    onDayPickerHide: handleDayPickerHide,
    format: getDateTimeFormatMapForLang(language).DATE_SHORT,
    formatDate: MomentLocaleUtils.formatDate,
    parseDate: MomentLocaleUtils.parseDate,
    dayPickerProps,
    inputProps: {
      autoComplete: 'off',
      id,
      onBlur: handleInputBlur,
      onTouchStart: handleTouchStart,
      readOnly,
    },
  };

  if (asCalendar) {
    const selectedDays = date ? [dayFactory({ date })] : [];

    return (
      <MultiDatePicker
        {...other}
        {...dayPickerProps}
        selectedDays={selectedDays}
        onChange={handleCalendarChange}
        disableBeforeDateTime={minDate}
        disableAfterDateTime={maxDate || undefined}
        holidays={holidays}
        language={language}
        validationError={validationErrorProp}
        validationType={validationType}
        serviceSubtitle={serviceSubtitle}
        serviceName={serviceName}
        newCalendarInteraction={newCalendarInteraction}
      />
    );
  }

  return (
    <LabelAndErrorFormGroup
      {...other}
      useLabelElementAsCaption={false}
      validationError={parseDatePopoverErrors(
        validationError || validationErrorProp,
        newCalendarInteraction
      )}
      validationType={validationType}
    >
      {({ ...formGroupProps }): JSX.Element => {
        // In some cases, we need the `id` attribute generated from the `LabelAndErrorFormGroup`
        dayPickerInputProps.inputProps.id = dayPickerInputProps.inputProps.id || formGroupProps.id;

        return (
          <DatePickerStylingWrapper
            className={className}
            readOnly={readOnly}
            styleAsError={!!validationError || !!validationErrorProp}
            calendarDropUp={calendarDirection === CalendarDirection.UP || calendarDropUp}
            ref={isMobileBrowser ? null : wrapperRef}
            calendarHeightOffset={calendarHeightOffset}
            calendarInset={calendarInset}
            calendarIsOpen={calendarIsOpen}
            newCalendarInteraction={newCalendarInteraction}
            isMobileBrowser={newCalendarInteraction && isMobileBrowser}
          >
            {newCalendarInteraction && renderInput()}
            {newCalendarInteraction &&
              showCalendar &&
              isMobileBrowser &&
              renderMobile(formGroupProps)}
            {newCalendarInteraction &&
              showCalendar &&
              !isMobileBrowser &&
              renderDesktop(formGroupProps)}
            {!newCalendarInteraction && (
              <DayPickerInput
                {...formGroupProps}
                {...dayPickerInputProps}
                // @ts-expect-error TS is being silly here, i18n._() can be called with string or MessageDescriptor
                placeholder={i18n._(placeholder)}
                inputProps={{
                  ...dayPickerInputProps.inputProps,
                  // This label helps screen readers tell users about the date format they must type
                  // It gets added here, so we have access to i18n
                  'aria-label': i18n._(
                    /* i18n: The var here will be a localized date string format, ex: 'date input in MM/DD/YYYY format' */
                    t`date input in ${getDateTimeFormatMapForLang(language).DATE_SHORT} format`
                  ),
                }}
                ref={dayPickerRef}
                keepFocus={false}
              />
            )}
            {!newCalendarInteraction && <StyledIcon onClick={showDatePicker} />}
          </DatePickerStylingWrapper>
        );
      }}
    </LabelAndErrorFormGroup>
  );
});

DatePicker.defaultProps = {
  allowKeyboardInput: true,
  asCalendar: false,
  calendarDirection: CalendarDirection.FLEX,
  className: '',
  date: undefined,
  holidays: [],
  id: '',
  isDayBlocked: undefined,
  maxDate: undefined,
  minDate: new Date(),
  placeholder: 'Select a Date',
  serviceSubtitle: '',
  serviceName: '',
  isMobileBrowser: false,
  ariaLabelledBy: '',
};

export default DatePicker;

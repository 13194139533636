import styled, { css } from 'styled-components';

import { Color, DSTokenMap, ZIndex } from '@rover/kibble/styles';
import { TEXT_100_FONT_SIZE } from '@rover/kibble/tokens/build/es6/DSToken';

type DatePickerStylingWrapperProps = {
  readOnly?: boolean;
  styleAsError?: boolean;
  calendarDropUp?: boolean;
  calendarHeightOffset?: number | null | undefined;
  calendarInset?: number;
  calendarIsOpen?: boolean;
  newCalendarInteraction?: boolean;
  isMobileBrowser?: boolean;
};

const getBorderforWrapper = (newCalendarInteraction: boolean, styleAsError: boolean): string => {
  if (newCalendarInteraction) {
    return '0px';
  }
  if (styleAsError) {
    return `1px solid ${DSTokenMap.BORDER_COLOR_ERROR.toString()}`;
  }
  return `1px solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`;
};

// eslint-disable-next-line rover/prefer-kibble-components
const DatePickerStylingWrapper = styled.div<DatePickerStylingWrapperProps>`
  position: relative;

  input {
    height: 40px;
    width: 100%;
    border-radius: ${DSTokenMap.BORDER_RADIUS_SECONDARY};
    padding: 8px;
    transition: border-color ease-in-out 0.15s;
    cursor: ${(props): string => (props.readOnly ? 'pointer' : 'text')};
    border: ${DSTokenMap.BORDER_WIDTH_PRIMARY} solid
      ${(props): string =>
        props.styleAsError
          ? DSTokenMap.BORDER_COLOR_ERROR.toString()
          : DSTokenMap.BORDER_COLOR_PRIMARY.toString()};

    &:focus {
      border-color: ${DSTokenMap.BORDER_COLOR_INPUT_FOCUS.toString()};
    }

    &:disabled {
      font-weight: 500;
      opacity: 1;
    }
  }

  .DayPickerInput {
    width: 100%;

    &-Overlay {
      opacity: ${
        ({ calendarIsOpen }): string =>
          calendarIsOpen || typeof calendarIsOpen === 'undefined' ? 'inherit' : '0'
        /* Wait until datepicker dropdown positioning has settled before making it visible */
      };
      z-index: ${ZIndex.DROPDOWN.toString()};
      position: absolute;
      left: ${(props): string => `-${props.calendarInset || 0}px`};
      ${(props) =>
        !props.calendarDropUp
          ? ''
          : css`
              bottom: 100%;
              margin-bottom: ${props.calendarHeightOffset || 0}px;
            `};
    }
  }

  .DayPicker {
    width: ${(props): string => (props.newCalendarInteraction ? '100%' : 'unset')};
    &-wrapper {
      border-radius: ${(props): string => (props.isMobileBrowser ? '0px' : '4px')};
      border: ${({ newCalendarInteraction, styleAsError }): string =>
        getBorderforWrapper(!!newCalendarInteraction, !!styleAsError)};
      position: relative;
      z-index: 1;
    }
    &-Caption > div {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      font-size: 16px;
      font-weight: bold;
    }

    &-Month {
      display: ${(props): string =>
        props.newCalendarInteraction && !props.isMobileBrowser ? 'block' : 'table'};
      border-collapse: ${(props): string => (props.newCalendarInteraction ? 'unset' : 'collapse')};
      border-spacing: ${(props): string => (props.newCalendarInteraction ? '1px' : '0px')};
      width: ${(props): string =>
        props.newCalendarInteraction && props.isMobileBrowser ? '100%' : 'unset'};
    }

    &-Weekday {
      color: ${DSTokenMap.TEXT_COLOR_PRIMARY.toString()};
      font-size: 13px;
    }

    &-Day {
      border-radius: 0;
      height: ${(props): string =>
        props.newCalendarInteraction && props.isMobileBrowser ? '50px' : '40px'};
      width: ${(props): string =>
        props.newCalendarInteraction && props.isMobileBrowser ? '50px' : '40px'};
      min-width: ${(props): string =>
        props.newCalendarInteraction && props.isMobileBrowser ? '50px' : '40px'};
      color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()};
      font-size: ${(props): string => (props.newCalendarInteraction ? TEXT_100_FONT_SIZE : '14px')};
      font-weight: ${(props): string => (props.newCalendarInteraction ? '700' : 'normal')};
      aspect-ratio: ${(props): string =>
        props.newCalendarInteraction && props.isMobileBrowser ? '1' : 'unset'};

      position: relative;

      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--between):hover {
        background-color: ${(props): string =>
          props.newCalendarInteraction
            ? `${Color.NEUTRAL_WHITE.toString()} !important`
            : `${Color.GREEN_200.toString()} !important`};
        border-radius: ${(props): string => (props.newCalendarInteraction ? '8px' : '50%')};
      }

      &--start {
        &:after {
          background-color: ${Color.GREEN_200.toString()} !important;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
        &.DayPicker-Day--selected {
          &:after {
            left: 50%;
          }
        }
      }

      &--end {
        &:before {
          background-color: ${Color.GREEN_200.toString()} !important;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
        &.DayPicker-Day--selected {
          &:before {
            right: 50%;
          }
        }
      }

      &--selected {
        &:not(.DayPicker-Day--outside) {
          background-color: ${(props): string =>
            props.newCalendarInteraction
              ? `${Color.NEUTRAL_WHITE.toString()} !important;`
              : `${Color.GREEN_600.toString()} !important;`};
          color: ${(props): string =>
            props.newCalendarInteraction
              ? `${DSTokenMap.TEXT_COLOR_PRIMARY.toString()} !important;`
              : `${DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()} !important;`};
          border: ${(props): string =>
            props.newCalendarInteraction
              ? `2px solid ${DSTokenMap.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE}`
              : '0'};
        }
        &:not(.DayPicker-Day--between):not(.DayPicker-Day--disabled) {
          border-radius: ${(props): string => (props.newCalendarInteraction ? '8px' : '50%')};
          &:not(.DayPicker-Day--outside):hover {
            background-color: ${(props): string =>
              props.newCalendarInteraction
                ? `${Color.NEUTRAL_WHITE.toString()} !important`
                : `${Color.GREEN_700.toString()} !important`};
          }
        }
        &.DayPicker-Day--between {
          background-color: ${Color.GREEN_200.toString()} !important;
          color: ${DSTokenMap.TEXT_COLOR_SECONDARY.toString()} !important;
          &:hover {
            border-radius: 0;
            background-color: ${(props): string =>
              props.newCalendarInteraction
                ? `${Color.NEUTRAL_WHITE.toString()} !important`
                : `${Color.GREEN_300.toString()} !important`};
            color: ${(props): string =>
              props.newCalendarInteraction
                ? `${DSTokenMap.TEXT_COLOR_PRIMARY.toString()} !important;`
                : `${DSTokenMap.TEXT_COLOR_SECONDARY.toString()} !important;`};
          }
        }
      }

      &--disabled {
        color: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()};
      }

      &--holiday {
        &:not(.DayPicker-Day--between):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-radius: 50%;
          background: ${DSTokenMap.BACKGROUND_COLOR_HIGHLIGHT.toString()};
        }
      }
    }
  }
`;

export default DatePickerStylingWrapper;

import React, { useEffect } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import moment from 'moment';
import styled from 'styled-components';

import { Calendar, Close } from '@rover/icons';
import { Box, Button, Flex, Heading, Paragraph } from '@rover/kibble/core';
import { Color, DSTokenMap, Spacing } from '@rover/kibble/styles';
import CalendarBottomSheet from '@rover/react-lib/src/components/BottomSheet/CalendarBottomSheet';
import { parseDatePopoverErrors } from '@rover/react-lib/src/components/datetime/DatePicker/DatePicker';
import InlineErrorDateInput from '@rover/react-lib/src/components/formFields/InlineErrorDateInput/InlineErrorDateInput';
import LabelAndErrorFormGroup, {
  Props as LabelAndErrorFormGroupProps,
} from '@rover/react-lib/src/components/utils/LabelAndErrorFormGroup';
import dayFactory from '@rover/react-lib/src/factories/dayFactory';
import useOnClickOutside from '@rover/react-lib/src/hooks/useOnClickOutside';
import { getStringFromDates } from '@rover/react-lib/src/utils/datetime';
import getMaxRequestDate from '@rover/react-lib/src/utils/getMaxRequestDate';
import { useI18n } from '@rover/rsdk/src/modules/I18n';
import { isMobileBrowser } from '@rover/rsdk/src/modules/Network/userAgent';
import getDateTimeFormatMapForLang from '@rover/shared/js/constants/i18n/datetime';
import { Day } from '@rover/types';
import { DateRangeField } from '@rover/types/src/datetime/DateRange';

import {
  createRenderDayForHolidays,
  DatePickerCaption,
  DatePickerNavbar,
  DatePickerStylingWrapper,
} from '../DatePicker';

export type Props<T extends Day> = {
  className?: string;
  disableAfterDateTime?: DateRangeField;
  disableBeforeDateTime?: DateRangeField;
  holidays?: Date[];
  initialMonth?: DateRangeField;
  language: string;
  onChange: (days: T[]) => void;
  disabledDays?: Array<Date | ((day: Date) => boolean) | Record<string, unknown> | undefined>; // from DayPicker docs: Date | Object | Date[] | (day: Date) ⇒ boolean
  selectedDays: T[];
  newCalendarInteraction?: boolean;
  serviceName?: MessageDescriptor | string;
  serviceSubtitle?: MessageDescriptor | string;
  maxDate?: DateRangeField;
  fromMonth?: DateRangeField;
  validationType: 'popover' | 'inline';
  ariaLabelledBy?: string;
} & LabelAndErrorFormGroupProps;

type DayPickerClickModifiers = {
  disabled: boolean;
  selected: boolean;
};

type KeyboardEvent = {
  key: string;
  preventDefault(): void;
};

const DATES_ERROR_MESSAGE = <Trans>Select at least one day</Trans>;

function checkDateErrors(selectedDays): undefined | JSX.Element {
  if (selectedDays.length > 0) {
    return undefined;
  }
  return DATES_ERROR_MESSAGE;
}

const StyledIcon = styled(Calendar)`
  position: absolute;
  height: 16px;
  width: 16px;
  right: ${Spacing.M.toString()};
  top: 11px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

const StyledCloseIcon = styled(Close)`
  height: 16px;
  width: 16px;
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  cursor: pointer;
`;

function handleDayClick<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void,
  clickedDate: Date,
  // eslint-disable-next-line default-param-last
  { selected, disabled }: DayPickerClickModifiers = { selected: false, disabled: false },
  language: string
): void {
  if (disabled) return;
  if (selected) {
    const selectedDates = selectedDays.map((d) => d.date);
    const selectedIndex = selectedDates.findIndex((d) => d && DateUtils.isSameDay(d, clickedDate));
    selectedDays.splice(selectedIndex, 1);
  } else {
    const DF = getDateTimeFormatMapForLang(language);
    selectedDays.push(
      // @ts-expect-error TS is mad we're assigning a Day to T, when T could have extra props.  This isn't great but the consumers of the component seem to handle it fine
      dayFactory({
        name: moment(clickedDate).format(DF.MONTH_DAY_MED),
        date: clickedDate,
      })
    );
  }
  onChange(selectedDays);
}

function clearDaysSelection<T extends Day>(
  selectedDays: T[],
  onChange: (days: Array<Day & T>) => void
): void {
  while (selectedDays.length > 0) {
    selectedDays.pop();
  }
  getStringFromDates(selectedDays);
  onChange(selectedDays);
}

function MultiDatePicker<T extends Day = Day>({
  className = '',
  disableAfterDateTime,
  disableBeforeDateTime = new Date(),
  holidays = [],
  initialMonth,
  language,
  onChange,
  disabledDays = [],
  selectedDays,
  newCalendarInteraction = false,
  serviceName,
  serviceSubtitle,
  maxDate,
  fromMonth,
  validationType,
  ariaLabelledBy,
  ...other
}: Props<T>): JSX.Element {
  const dayPickerProps = {
    fromMonth: fromMonth || disableBeforeDateTime,
    toMonth: disableAfterDateTime,
    initialMonth,
    disabledDays: [{ before: disableBeforeDateTime, after: disableAfterDateTime }, ...disabledDays],
    selectedDays: selectedDays.map((d) => d.date),
    onDayClick: (clickedDate, modifiers) =>
      handleDayClick<T>(selectedDays, onChange, clickedDate, modifiers, language),
    navbarElement: DatePickerNavbar,
    captionElement: DatePickerCaption,
    localeUtils: MomentLocaleUtils,
    locale: language,
    modifiers: {
      holiday: holidays,
    },
    renderDay: createRenderDayForHolidays(holidays),
  };

  const [showCalendar, setShowCalendar] = React.useState<boolean | null>(null);
  const calendarWrapperRef = React.useRef<HTMLDivElement>(null);
  const [localError, setLocalError] = React.useState<undefined | JSX.Element>(undefined);

  const { i18n } = useI18n();

  useOnClickOutside(calendarWrapperRef, () => {
    if (showCalendar) {
      setShowCalendar(false);
      setLocalError(checkDateErrors(selectedDays));
    }
  });

  useEffect(() => {
    if (showCalendar && isMobileBrowser()) {
      document.querySelector('.DayPicker-Day.DayPicker-Day--today')?.scrollIntoView(false);
    }
    if (showCalendar === false) setLocalError(checkDateErrors(selectedDays));
  }, [showCalendar]);

  const numberOfMonthsAllowed = (): number => {
    if (maxDate) {
      // Adding 2 to the result to count both first and last month
      return Math.abs(moment().diff(moment(maxDate), 'months')) + 2;
    }
    // Adding 2 to the result to count both first and last month
    return Math.abs(moment().diff(moment(getMaxRequestDate()), 'months')) + 2;
  };

  const renderInput = (validationError, isMobile = false): React.ReactNode => {
    const dateInputString = getStringFromDates(selectedDays);
    return (
      <Box
        onClick={(): void => {
          !isMobile && setShowCalendar(!showCalendar);
        }}
        className="multiDateAutoFillPicker-clickable-wrapper"
        maxHeight={isMobile ? undefined : '40px'}
        width={isMobileBrowser() && showCalendar ? '80vw' : 'unset'}
        position="relative"
        paddingBottom="4x"
        role="button"
      >
        <InlineErrorDateInput
          onKeyDown={(event: KeyboardEvent): void => {
            if (event.key === 'Enter') {
              setShowCalendar(!showCalendar);
            } else if (event.key === ' ') {
              event.preventDefault();
              setShowCalendar(!showCalendar);
            }
          }}
          value={typeof dateInputString === 'string' ? dateInputString : i18n._(dateInputString)}
          errorMessage={(validationError || localError) && checkDateErrors(selectedDays)}
          aria-labelledby={ariaLabelledBy}
          onClick={(): void => {
            setShowCalendar(!showCalendar);
          }}
        />
        {selectedDays.length >= 1 ? (
          <Box
            onClick={(): void => {
              setShowCalendar(!showCalendar);
            }}
            sx={{
              position: 'absolute',
              width: '16px',
              height: '16px',
              right: Spacing.M.toString(),
              top: '11px',
              zIndex: 10,
            }}
          >
            <StyledCloseIcon />
          </Box>
        ) : (
          <StyledIcon />
        )}
      </Box>
    );
  };

  const renderMobile = (formGroupProps): JSX.Element => {
    return (
      <Box
        position="fixed"
        pb="0x"
        pl="0x"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        sx={{
          backgroundColor: DSTokenMap.BACKGROUND_COLOR_OVERLAY.toString(),
        }}
        zIndex="10000"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <CalendarBottomSheet reference={calendarWrapperRef} onClose={() => setShowCalendar(false)}>
          <Heading size="200" mt="8x" mb="1x">
            {i18n._(serviceName as string)}
          </Heading>
          <Paragraph size="100" mb="6x" textAlign="center" maxWidth="80vw">
            {i18n._(serviceSubtitle as string)}
          </Paragraph>
          {renderInput(other.validationError || localError, true)}
          <Box
            sx={{
              overflow: 'scroll',
            }}
          >
            <DayPicker
              {...formGroupProps}
              {...dayPickerProps}
              numberOfMonths={numberOfMonthsAllowed()}
              canChangeMonth={false}
            />
          </Box>
          <Box
            position="fixed"
            bottom="0"
            padding="2x"
            paddingBottom="6x"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ backgroundColor: Color.NEUTRAL_WHITE.toString() }}
            width="100vw"
            height="64px"
            zIndex="30"
          >
            <Button
              backgroundColor={DSTokenMap.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY.toString()}
              fullWidth
              padding={DSTokenMap.SPACE_0X}
              color={DSTokenMap.TEXT_COLOR_PRIMARY_INVERSE.toString()}
              onClick={(): void => {
                const error = checkDateErrors(selectedDays);
                setLocalError(error);
                if (!error) {
                  setShowCalendar(false);
                }
              }}
            >
              {i18n._('Save dates')}
            </Button>
          </Box>
        </CalendarBottomSheet>
      </Box>
    );
  };

  const renderDesktop = (formGroupProps): JSX.Element => {
    return (
      <Box
        pb="0x"
        mt="5x"
        sx={{ backgroundColor: 'white' }}
        width="640px"
        border={`${DSTokenMap.BORDER_WIDTH_PRIMARY.toString()} solid ${DSTokenMap.BORDER_COLOR_PRIMARY.toString()}`}
        borderRadius={DSTokenMap.BORDER_RADIUS_SECONDARY}
      >
        <DayPicker {...formGroupProps} {...dayPickerProps} numberOfMonths={2} />
        <Flex flexDirection="row" justifyContent="space-between" margin="6x" marginTop="3x">
          <Button
            onClick={() => clearDaysSelection(selectedDays, onChange)}
            type="button"
            width={180}
            paddingY="2x"
            data-qa-id="clear-selection-button"
            aria-label="Clear selection"
            size="small"
          >
            {i18n._('Reset dates')}
          </Button>
          <Button
            onClick={() => setShowCalendar(false)}
            type="button"
            width={180}
            variant="primary"
            paddingY="2x"
            data-qa-id="save-selection-button"
            data-testid="save-selection-button"
            aria-label="Save dates"
            size="small"
          >
            {i18n._('Save dates')}
          </Button>
        </Flex>
      </Box>
    );
  };

  return (
    <LabelAndErrorFormGroup
      {...other}
      validationError={parseDatePopoverErrors(other.validationError, newCalendarInteraction)}
      validationType={validationType}
      useLabelElementAsCaption={false}
    >
      {(formGroupProps): JSX.Element => (
        <DatePickerStylingWrapper
          className={className}
          styleAsError={!!(other.validationError || localError)}
          isMobileBrowser={newCalendarInteraction && isMobileBrowser()}
          newCalendarInteraction={newCalendarInteraction}
          ref={isMobileBrowser() ? null : calendarWrapperRef}
        >
          {newCalendarInteraction && renderInput(other.validationError || localError)}
          {newCalendarInteraction &&
            showCalendar &&
            isMobileBrowser() &&
            renderMobile(formGroupProps)}
          {newCalendarInteraction &&
            showCalendar &&
            !isMobileBrowser() &&
            renderDesktop(formGroupProps)}
          {!newCalendarInteraction && <DayPicker {...formGroupProps} {...dayPickerProps} />}
        </DatePickerStylingWrapper>
      )}
    </LabelAndErrorFormGroup>
  );
}

export default MultiDatePicker;

/* eslint-disable simple-import-sort/imports */
import moment from 'moment';

// DON'T CHANGE THE IMPORT ORDER!
// Since all imports are hoisted and importing a new moment locale
// automatically sets the global moment instance to that locale, we
// need to import the current moment locale from another file
// to ensure it is preserved before importing the other supported locales
import currentLanguage from './getCurrentMomentLocale';

import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/de-at';
import 'moment/locale/de-ch';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/nl-be';
import 'moment/locale/pl';
import 'moment/locale/sv';

moment.locale(currentLanguage);

import { Close } from '@rover/icons';
import { Box, Button, Flex } from '@rover/kibble/core';

import { DismissButtonProps } from './BottomSheet.common';

export const DismissButton: React.FC<DismissButtonProps> = ({
  isDismissible,
  hideCloseIcon,
  onRequestClose,
  closeOnDragDown,
}: DismissButtonProps) => {
  if (isDismissible && !hideCloseIcon && !closeOnDragDown) {
    return (
      <Flex paddingX="4x" flexDirection="column" alignItems="flex-end">
        <Box>
          <Button
            icon={Close}
            variant="default"
            style={{ padding: '4', boxShadow: 'none' }}
            onClick={onRequestClose}
            size="small"
            data-testid="modal-dismiss-button"
            aria-label="Dismiss modal"
          />
        </Box>
      </Flex>
    );
  }

  return null;
};

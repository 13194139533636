import { PaddingSystemProps } from '@rover/kibble/core/styledSystemPropTypes';

export type BottomSheetCommonProps = {
  children: React.ReactNode;
  isOpen: boolean;
  hideCloseIcon?: boolean;
  closeOnDragDown?: boolean;
  isDismissible?: boolean /** Web ONLY */;
  contentPaddingStyles?: Partial<PaddingSystemProps>;
  onRequestClose: (payload?: unknown) => void;
};

export const defaultContentPaddingStyles: Partial<PaddingSystemProps> = { paddingX: '4x' };

export type DismissButtonProps = Pick<
  BottomSheetCommonProps,
  'isDismissible' | 'hideCloseIcon' | 'onRequestClose' | 'closeOnDragDown'
>;

import React from 'react';

import { Flex } from '@rover/kibble/core';
import { DSTokenMap, ZIndex } from '@rover/kibble/styles';

import { OverlayProps } from './Overlay.common';

export type Props = OverlayProps;

const Overlay = (props: Props): JSX.Element => {
  return (
    <Flex
      {...props}
      alignItems="center"
      sx={{
        backgroundColor: DSTokenMap.BACKGROUND_COLOR_CONTRAST.withOpacity(0.8),
        ...props.sx,
      }}
      bottom="0"
      height="100%"
      justifyContent="center"
      left="0"
      padding="3x"
      position="fixed"
      right="0"
      top="0"
      width="100vw"
      zIndex={ZIndex.MODAL_OVERLAY.toString()}
    />
  );
};

export default Overlay;

import React, { useEffect } from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { Close } from '@rover/icons';
import { Box, Button } from '@rover/kibble/core';
import { Color, DSTokenMap, Spacing, ZIndex } from '@rover/kibble/styles';
import { useI18n } from '@rover/rsdk/src/modules/I18n';

export type BottomSheetProps = {
  children: React.ReactNode | React.ReactNode[];
  reference: React.RefObject<HTMLDivElement>;
  fullHeight?: boolean;
  onClose: () => void;
};

export const buttonStyle = {
  border: '0px',
  display: 'flex',
  padding: 0,
  position: 'absolute',
  right: Spacing.M.toString(),
  top: Spacing.M.toString(),
  zindex: ZIndex.MODAL.toString(),
  boxShadow: 'none',
} as React.CSSProperties;

export const StyledCloseIcon = styled(Close)`
  height: ${Spacing.M.toString()};
  width: ${Spacing.M.toString()};
  fill: ${DSTokenMap.TEXT_COLOR_TERTIARY.toString()};
  pointer-events: none;
`;

const CalendarBottomSheet = ({
  children,
  reference,
  fullHeight,
  onClose,
}: BottomSheetProps): JSX.Element => {
  const { i18n } = useI18n();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  });
  return (
    <Box
      borderTopLeftRadius={fullHeight ? '0px' : Spacing.M.toString()}
      borderTopRightRadius={fullHeight ? '0px' : Spacing.M.toString()}
      paddingBottom="12x"
      sx={{ backgroundColor: Color.NEUTRAL_WHITE.toString(), overflow: 'scroll' }}
      zIndex="20"
      maxWidth="100%"
      maxHeight={fullHeight ? '100vh' : '80vh'}
      height={fullHeight ? '100%' : undefined}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      ref={reference}
    >
      {fullHeight && (
        <Button onClick={onClose} aria-label={i18n._(t`Close`)} style={buttonStyle}>
          <StyledCloseIcon />
        </Button>
      )}
      {children}
    </Box>
  );
};

export default CalendarBottomSheet;

import React from 'react';
import styled from 'styled-components';

import { CarouselLeft, CarouselRight } from '@rover/icons';
import { DSTokenMap } from '@rover/kibble/styles';

export type Props = {
  onPreviousClick: () => void;
  onNextClick: () => void;
  showNextButton: boolean;
  showPreviousButton: boolean;
};

const Wrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 12px;
  left: auto;

  svg {
    cursor: pointer;
    padding: 8px;
    margin-left: 8px;

    &[disabled] {
      cursor: not-allowed;
      stroke: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()};
      fill: ${DSTokenMap.INTERACTIVE_TEXT_COLOR_DISABLED.toString()};
    }
  }
`;

const DatePickerNavbar = ({
  showNextButton,
  showPreviousButton,
  onPreviousClick,
  onNextClick,
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <CarouselLeft
        tabIndex="0"
        role="button"
        aria-label="Previous Month"
        disabled={!showPreviousButton}
        aria-disabled={!showPreviousButton}
        onClick={() => onPreviousClick()} // this arrow func is needed to prevent passing the click event to the handler
      />
      <CarouselRight
        tabIndex="0"
        role="button"
        aria-label="Next Month"
        disabled={!showNextButton}
        aria-disabled={!showNextButton}
        onClick={() => onNextClick()} // this arrow func is needed to prevent passing the click event to the handler
      />
    </Wrapper>
  );
};

export default DatePickerNavbar;

import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { Box, Flex } from '@rover/kibble/core';
import Overlay from '@rover/kibble/official-patterns/Modal/Overlay';
import { DSTokenMap, ZIndex } from '@rover/kibble/styles';

import { SpacingSystemType } from '../../core/styledSystemPropTypes';
import { usePreventScroll, useReactModalSetup } from '../Modal/Modal.common';

import { BottomSheetCommonProps, defaultContentPaddingStyles } from './BottomSheet.common';
import { DismissButton } from './DismissButton';

export const CLOSE_TIMEOUT = 300;

const overlayElement = (
  props: React.ComponentPropsWithRef<'div'>,
  contentEl: React.ReactNode
): JSX.Element => (
  <Overlay data-testid="overlay" {...props}>
    {contentEl}
  </Overlay>
);

export type Props = BottomSheetCommonProps & {
  isDismissible?: boolean;
  /**
   * Whether the BottomSheet should use the full screen height.
   *
   * **Only available on web**
   */
  isFullHeight?: boolean;
  style?: ReactModal.Styles;
  headerId?: string;
  HeaderComponent?: React.ReactNode;
  FooterComponent?: React.ReactNode;
  appElementSelector?: string;
  overflowY?: 'auto' | 'hidden';
  /**
   * The height of the BottomSheet.
   *
   * **Only available on RxN.**
   *
   * On web, the BottomSheet will always fit to content along with applying default min and max heights.
   *
   * - Given an undefined height:
   *   - The BottomSheet will use a default height based on screen width.
   * - Given a height of -1:
   *   - The BottomSheet will use the height of its content. If height exceeds the screen height,
   *     the BottomSheet will use the full screen.
   * - Given a height >= 0:
   *   - BottomSheet will have a fixed height.
   *
   * ## Usage notes:
   *   - The prop appears to somehow get memoised. Therefore if for example you set height to -1 then
   *     500, it will not update the height to 500.
   */
  height?: number | number[];
  /**
   * The direction of the slide-in/out animation (for full-height bottom sheets only).
   *
   * **Options:**
   * - `"vertical"` – the bottom sheet slides in from the bottom to the top (default)
   * - `"horizontal"` – the bottom sheet slides in from the right to the left
   */
  animationDirection?: 'vertical' | 'horizontal';
};

const BottomSheet = ({
  isOpen,
  isDismissible = true,
  closeOnDragDown = false,
  isFullHeight,
  onRequestClose,
  children,
  style,
  headerId,
  HeaderComponent,
  FooterComponent,
  appElementSelector = '#base-content',
  hideCloseIcon = false,
  overflowY,
  contentPaddingStyles = defaultContentPaddingStyles,
  animationDirection: _animationDirection = 'vertical',
}: Props): JSX.Element => {
  const { staticHeaderId, modalId } = useReactModalSetup(appElementSelector);
  usePreventScroll(isOpen, modalId);

  const [isDragging, setIsDragging] = useState(false);
  const [initialY, setInitialY] = useState(null);
  const [currentY, setCurrentY] = useState(0);

  /** The effective animation direction, as `'horizontal'` would not make sense with a non-full height BottomSheet */
  const appliedAnimationDirection = isFullHeight ? _animationDirection : 'vertical';
  const transformOpen =
    appliedAnimationDirection === 'horizontal' ? 'translateX(0)' : 'translateY(0)';
  const transformClosed =
    appliedAnimationDirection === 'horizontal' ? 'translateX(100%)' : 'translateY(100%)';
  const [transform, setTransform] = useState<CSSProperties['transform']>(
    isOpen ? transformOpen : transformClosed
  );

  // BottomSheet first renders with `isOpen=true`, but we want it to start offscreen so it slides in smoothly,
  // so this offsets the transform state by one frame
  useEffect(() => {
    if (isOpen) {
      setTransform(transformOpen);
    } else {
      setTransform(transformClosed);
    }
  }, [isOpen, transformClosed, transformOpen]);

  const startDrag = (e) => {
    setIsDragging(true);
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    setInitialY(clientY);
  };

  const onDrag = useCallback(
    (e) => {
      if (!isDragging) return;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      const deltaY = clientY - (initialY ?? clientY);
      setCurrentY(Math.max(deltaY, 0)); // Prevent dragging up beyond initial position
    },
    [isDragging, initialY]
  );

  const endDrag = useCallback(() => {
    setIsDragging(false);
    if (currentY > 75) {
      // Threshold to close the sheet
      onRequestClose();
    } else {
      setCurrentY(0); // Snap back to the original position
    }
  }, [currentY, onRequestClose]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', endDrag);
      document.addEventListener('touchmove', onDrag);
      document.addEventListener('touchend', endDrag);
    } else {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', endDrag);
    }

    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', endDrag);
    };
  }, [isDragging, endDrag, onDrag]);

  useEffect(() => {
    if (isOpen) {
      setCurrentY(0);
    }
  }, [isOpen]);

  let paddingTop: SpacingSystemType = '8x';
  if (closeOnDragDown) {
    paddingTop = '0x';
  } else if (isFullHeight) {
    paddingTop = '4x';
  }

  const transition =
    appliedAnimationDirection === 'horizontal'
      ? 'transform 0.4s cubic-bezier(0, 0.78, 0, 0.99), opacity 0.3s'
      : 'transform 0.3s, opacity 0.3s';

  return (
    <ReactModal
      testId="bottom-sheet"
      className="RoverModalContent"
      style={{
        content: {
          width: '100%',
          ...(isFullHeight
            ? {
                height: '100%',
              }
            : {
                position: 'absolute',
                bottom: `-${currentY}px`,
                left: 0,
                right: 0,
              }),
          transform,
          opacity: isOpen ? 1 : 0 /* Start with opacity 0 */,
          transition,
          ...(style?.content && { ...style.content }),
        },
        overlay: {
          padding: 0,
          ...(style?.overlay && { ...style.overlay }),
        },
      }}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="RoverModalOverlay"
      overlayElement={overlayElement}
      shouldCloseOnOverlayClick={isDismissible}
      shouldCloseOnEsc={isDismissible}
    >
      <Flex
        aria-labelledby={headerId || staticHeaderId}
        background="primary"
        flexDirection="column"
        {...(isFullHeight
          ? {
              height: '100%',
              overflowY: 'auto',
            }
          : {
              borderRadius: 'primary',
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            })}
        minHeight="100px"
        paddingTop={paddingTop}
        paddingBottom="6x"
        zIndex={ZIndex.MODAL.toString()}
      >
        {closeOnDragDown && (
          <Flex
            alignItems="center"
            width="100%"
            height="48px"
            display="flex"
            justifyContent="center"
            onMouseMove={onDrag}
            onMouseUp={endDrag}
            onTouchMove={onDrag}
            onTouchEnd={endDrag}
            onMouseDown={startDrag}
            onTouchStart={startDrag}
          >
            <Flex
              width="50px"
              height="5px"
              backgroundColor={DSTokenMap.BORDER_COLOR_PRIMARY.toString()}
              borderRadius="primary"
              tabIndex={0}
              onClick={onRequestClose}
              data-testid="modal-dismiss-button"
              aria-label="Dismiss modal"
              role="button"
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  onRequestClose();
                }
              }}
            />
          </Flex>
        )}
        <Flex flexDirection="column" height="100%" {...(!isFullHeight && { maxHeight: '80vh' })}>
          <DismissButton
            isDismissible={isDismissible}
            hideCloseIcon={hideCloseIcon}
            closeOnDragDown={closeOnDragDown}
            onRequestClose={onRequestClose}
          />
          <Box {...contentPaddingStyles}>{HeaderComponent}</Box>
          <Box {...contentPaddingStyles} sx={{ overflowY }} height={isFullHeight ? '100%' : 'auto'}>
            {children}
          </Box>
          <Box {...contentPaddingStyles}>{FooterComponent}</Box>
        </Flex>
      </Flex>
    </ReactModal>
  );
};

export default BottomSheet;

import React, { useEffect, useId, useMemo } from 'react';
import ReactModal from 'react-modal';

import { Props as FlexProps } from '@rover/kibble/core/Flex/Flex';
import NoScrollController from '@rover/kibble/official-patterns/Modal/utils/NoScrollController';
import type { ThemeHeadingSizes } from '@rover/kibble/styles';

export type DialogButtonProps = {
  buttonDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  cancelButtonText?: string;
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  submitButtonText?: string;
  submitButtonDisabled?: boolean;
  submitButtonLoading?: boolean;
};

export type ModalCommonProps = DialogButtonProps & {
  children: React.ReactNode;
  isOpen: boolean;
  showCloseIcon?: boolean;
  onRequestClose: (payload?: unknown) => void;
  headerText?: string;
  headerSize?: ThemeHeadingSizes;
  overlaySx?: React.CSSProperties;
  contentProps?: FlexProps;
};

export const usePreventScroll = (isOpen: boolean, modalId: string): void => {
  // Prevent scrolling when modal is open
  const noScroll = new NoScrollController();
  useEffect(() => {
    if (isOpen) {
      noScroll.on(modalId);
    } else {
      noScroll.off(modalId);
    }
    return () => {
      noScroll.off(modalId);
    };
  }, [isOpen, modalId]);
};

export const useReactModalSetup = (appElementSelector: string) => {
  // setAppElement has to be called before render or else we get react-modal errors.
  // useMemo with an empty array second arg will run only once, before render, much like componentWillMount does.
  // react-modal's internals make this method safe to call server-side.
  useMemo(() => {
    ReactModal.setAppElement(appElementSelector);
  }, []);

  const staticHeaderId = useId();
  const modalId = useId();

  return { staticHeaderId, modalId };
};
